<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 법령 -->
          <c-select
            type="search"
            codeGroupCd="LAW_ID"
            itemText="codeName"
            itemValue="code"
            label="LBL0010071"
            name="lawId"
            v-model="searchParam.lawId"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사용여부 -->
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            label="LBLUSEFLAG"
            name="useFlag"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <!--법령별 구분-->
    <c-table
      ref="gubunTable"
      title=""
      tableId="gubunTable"
      :columnSetting="false"
      :isFullScreen="false"
      :usePaging="false"
      :isExcelDown="false"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      @innerBtnClicked="innerBtnClicked"
      @table-data-change="tableDataChange"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!--법령추가-->
          <c-btn 
            v-if="editable" 
            label="LBL0010073" 
            icon="add" 
            @btnClicked="addRowRaw" />
          <!--저장-->
          <c-btn 
            v-if="editable" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="saveData"
            mappingType="PUT"
            label="LBLSAVE" 
            icon="save" 
            @beforeAction="saveInfo"
            @btnCallback="saveInfoCallback" />
          <!--검색-->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template>
          <c-text-column
            :editable="editable"
            :col="col"
            :props="props"
            @datachange="datachange(props, col)"
          />
          <div v-if="editable">
            <q-btn-group outline class="ColumInnerBtnGroup">
              <q-btn
                icon="add"
                color="red-6"
                text-color="white"
                class="ColumInnerBtn"
                align="center"
                @click.prevent="innerBtnClicked(col, props)">
                <q-tooltip>
                  <!--구분별 항목 추가-->
                  {{$label('LBL0010074')}}
                </q-tooltip>
              </q-btn>
            </q-btn-group>
          </div>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';

export default {
  name: 'law-register',

  data() {
    return {
      searchParam: {
        lawId: null,
        useFlag: 'Y',
      },
      grid: {
        merge: [
          { index: 0, colName: 'lawId' },
          { index: 1, colName: 'saiLawRegisterMasterId' },
        ],
        columns: [
          
        ],
        data: [],
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      saveData: [],
      isSave: false,
      editable: true,
      listUrl: '',
      saveUrl: '',
      popupOptions: {
        isFull: false,
        width: '40%',
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.lawRegister.item.list.url; // selectConfig.sai.lawRegister.gubun.list.url;
      this.saveUrl = transactionConfig.sai.lawRegister.gubun.save.url;
      // code setting
      // list setting
      this.setHeader();
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    setHeader() {
      this.grid.columns = [
        {
          name: 'lawName',
          field: 'lawName',
          //법령명
          label: 'LBL0010058',
          align: 'center',
          style: 'width:100px',
          sortable: false,
          innerBtn: true,
          btns: [
            { label: '', icon: 'add', color: 'light-blue', tooltip: this.$comm.getLangLabel('LBL0010607') }, // 법령별 구분 추가
          ],
        },
        {
          required: true,
          name: 'gubunName',
          field: 'gubunName',
          //구분
          label: 'LBL0010059',
          align: 'left',
          style: 'width:150px',
          type: 'custom',
          sortable: false,
        },
        {
          required: true,
          name: 'itemName',
          field: 'itemName',
          //항목
          label: 'LBLITEMS',
          type: 'text',
          align: 'left',
          style: 'width:150px',
          sortable: true,
        },
        {
          required: true,
          name: 'relevantClause',
          field: 'relevantClause',
          //관련조항
          label: 'LBL0010060',
          type: 'text',
          align: 'center',
          style: 'width:100px',
          sortable: true,
        },
        {
          name: 'requirements',
          field: 'requirements',
          //요구사항
          label: 'LBL0010061',
          type: 'textarea',
          align: 'left',
          sortable: true,
        },
        {
          name: 'applyConstruction',
          field: 'applyConstruction',
          //적용사항
          label: 'LBL0010062',
          type: 'text',
          align: 'left',
          style: 'width:140px',
          sortable: true,
        },
        {
          setHeader: true,
          name: 'target',
          field: 'target',
          //적용대상
          label: 'LBL0010063',
          type: 'text',
          align: 'center',
          style: 'width:90px',
          sortable: true,
        },
        {
          name: 'relatedDocument',
          field: 'relatedDocument',
          //관련문서 (기록)
          label: 'LBL0010064',
          type: 'textarea',
          align: 'left',
          style: 'width:200px',
          sortable: true,
        },
        {
          name: 'useFlag',
          field: 'useFlag',
          //사용여부
          label: 'LBLUSEFLAG',
          type: 'check',
          sortable: true,
          align: 'center',
          style: 'width:80px',
          true: 'Y',
          false: 'N'
        },
        {
          name: 'sortOrder',
          field: 'sortOrder',
          //정렬순서
          label: 'LBLSORTORDER',
          align: 'center',
          sortable: true,
          style: 'width:80px',
          type: 'number',
        },
      ]
    },
    datachange(props) {
      if (props.row['mstEditFlag'] !== 'C') {
        props.row['mstEditFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    tableDataChange(props, col) {
      if (col.name !== 'gubunName' && props.row['itemEditFlag'] !== 'C') {
        props.row['itemEditFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    innerBtnClicked(col, props) {
      if (col.name === 'lawName') {
        // 법령별 구분 추가
        this.grid.data.splice(props.rowIndex + 1, 0, {
          saiLawRegisterMasterId: uid(),  // 법규등록부 구분 일련번호
          lawId: props.row.lawId,
          lawName: props.row.lawName,
          gubunName: '',  // 구분
          useFlag: 'Y',  // 사용여부
          mstEditFlag: 'C',
          regUserId: this.$store.getters.user.userId,
          // 항목 정보
          saiLawRegisterMasterItemId: uid(),  // 법규등록부 항목 일련번호
          sortOrder: 0,
          itemName: '',  // 항목
          relevantClause: '',  // 관련조항
          requirements: '',  // 요구사항
          applyConstruction: '',  // 적용사항
          target: '',  // 적용대상
          relatedDocument: '',  // 관련문서(기록)
          itemEditFlag: 'C'
        })
      } else {
        // 구분별 항목 추가
        this.grid.data.splice(props.rowIndex + 1, 0, {
          saiLawRegisterMasterId: props.row.saiLawRegisterMasterId,  // 법규등록부 구분 일련번호
          lawId: props.row.lawId,
          lawName: props.row.lawName,
          gubunName: props.row.gubunName,  // 구분
          useFlag: props.row.useFlag,  // 사용여부
          regUserId: this.$store.getters.user.userId,
          sortOrder: 0,
          // 항목 정보
          saiLawRegisterMasterItemId: uid(),  // 법규등록부 항목 일련번호
          itemName: '',  // 항목
          relevantClause: '',  // 관련조항
          requirements: '',  // 요구사항
          applyConstruction: '',  // 적용사항
          target: '',  // 적용대상
          relatedDocument: '',  // 관련문서(기록)
          itemEditFlag: 'C'
        })
      }
    },
    addRowRaw() {
      this.popupOptions.title = 'LBL0010071';   // 법령
      this.popupOptions.param = {
        type: 'multiple',
      };
      this.popupOptions.target = () => import(`${'./lawGubunPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeLawGubunPopup;
    },
    closeLawGubunPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          let _idx = this.$_.findIndex(this.grid.data, { lawId: item.code });
          _idx = _idx > -1 ? _idx : 0
          this.grid.data.splice(_idx, 0, {
            saiLawRegisterMasterId: uid(),  // 법규등록부 구분 일련번호
            lawId: item.code,
            lawName: item.codeName,
            gubunName: '',  // 구분
            useFlag: 'Y',  // 사용여부
            mstEditFlag: 'C',
            regUserId: this.$store.getters.user.userId,
            sortOrder: this.grid.data.length + 1,
            // 항목 정보
            saiLawRegisterMasterItemId: uid(),  // 법규등록부 항목 일련번호
            itemName: '',  // 항목
            relevantClause: '',  // 관련조항
            requirements: '',  // 요구사항
            applyConstruction: '',  // 적용사항
            target: '',  // 적용대상
            relatedDocument: '',  // 관련문서(기록)
            itemEditFlag: 'C'
          })
        })
      }
    },
    saveInfo() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        /**
         * [구분 - 항목] 데이터 분리
         * 분리시에 수정&등록된 데이터만 처리
         */
        let saveData = this.grid.data.filter(item => {
          return item.mstEditFlag === 'C' || item.mstEditFlag === 'U' 
            || item.itemEditFlag === 'C' || item.itemEditFlag === 'U'
        });
        this.$set(this.$data, 'saveData', this.$_.map(this.$_.uniqBy(saveData, 'saiLawRegisterMasterId'), (mst) => {
          return {
            saiLawRegisterMasterId: mst.saiLawRegisterMasterId,  // 법규등록부 구분 일련번호
            lawId: mst.lawId,
            lawName: mst.lawName,
            gubunName: mst.gubunName,  // 구분
            useFlag: 'Y',  // 사용여부
            editFlag: mst.mstEditFlag,
            regUserId: this.$store.getters.user.userId,
            chgUserId: this.$store.getters.user.userId,
            items: this.$_.map(this.$_.filter(saveData, { saiLawRegisterMasterId: mst.saiLawRegisterMasterId }), (item) => {
              return {
                saiLawRegisterMasterId: mst.saiLawRegisterMasterId,  // 법규등록부 구분 일련번호
                saiLawRegisterMasterItemId: item.saiLawRegisterMasterItemId,  // 법규등록부 항목 일련번호
                itemName: item.itemName,  // 항목
                relevantClause: item.relevantClause,  // 관련조항
                requirements: item.requirements,  // 요구사항
                applyConstruction: item.applyConstruction,  // 적용사항
                target: item.target,  // 적용대상
                relatedDocument: item.relatedDocument,  // 관련문서(기록)
                useFlag: item.useFlag,  // 사용여부
                sortOrder: item.sortOrder,
                editFlag: item.itemEditFlag,
                regUserId: this.$store.getters.user.userId,
                chgUserId: this.$store.getters.user.userId,
              }
            })
          }
        }));
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',  // 확인
            message: 'MSGSAVE', // 저장하시겠습니까?   //저장하시겠습니까?
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE ', /* 안내 */
            message: 'MSGNOCHANGEDATA', // 변경된 데이터가 없습니다., /* 변경된 데이터가 없습니다. */
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    saveInfoCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    }
  }
};
</script>
